import { Row, Col, Container, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { Globals } from "../globals";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useEffect, useState } from "react";

function Settings(props) {
  const [anonymous, setAnonymous] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  useEffect(() => {
    Auth.currentSession()
      .catch(err => console.error(err))
      .then(res => {
        const accessToken = res.getAccessToken();
        const jwt = accessToken.getJwtToken();

        const options = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }

        // Fetch user session
        if (props.activeUser.username) {
          fetch(`${Globals.authApiUrl}/web/session?userId=${props.activeUser.username}`, options)
            .then(response => response.json())
            .then(data => {
              console.log(data);
              setAnonymous(data.anonymous);
              setIsPrivate(data.private);
            });
        }
      });
  }, [setAnonymous, setIsPrivate, props.activeUser.username])

  const changeSessionSetting = (checked, name) => {
    Auth.currentSession()
      .catch(err => console.error(err))
      .then(res => {
        const accessToken = res.getAccessToken();
        const jwt = accessToken.getJwtToken();
        const body = {
          userId: props.activeUser.username
        };
        body[name] = checked;

        const options = {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${jwt}`
          },
          body: JSON.stringify(body)
        }
    
        // Update anonymous session
        fetch(`${Globals.authApiUrl}/web/${name}`, options)
          .then(response => {
            console.log(response);
          });
      });
  }

  const onAnonymousSessionChange = (checked) => {
    changeSessionSetting(checked, "anonymousSession");
    setAnonymous(checked)
  }

  const onPrivateSessionChange = (checked) => {
    changeSessionSetting(checked, "privateSession");
    setIsPrivate(checked)
  }

  return (
    <Container fluid className="profileContainer">
      <Row style={{ paddingTop: '1em', paddingBottom: '1em' }}>
        <Col>
          <b style={{ float: 'left' }}>Settings:</b>
        </Col>
      </Row>
      <Form>
        <Row style={{ paddingTop: '.5em', paddingBottom: '.5em' }}>
          <Col>
            <p style={{ float: 'left' }}>Private Session</p>
          </Col>
          <Col>
            <BootstrapSwitchButton
              type="checkbox"
              checked={isPrivate}
              style={{ float: 'right' }}
              onChange={ (checked) => onPrivateSessionChange(checked) }
              onstyle="outline-success"
              offstyle="outline-secondary"
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: '.5em', paddingBottom: '.5em' }}>
          <Col>
            <p style={{ float: 'left' }}>Anonymous Session</p>
          </Col>
          <Col>
            <BootstrapSwitchButton
              type="checkbox"
              checked={anonymous}
              style={{ float: 'right' }}
              onChange={ (checked) => onAnonymousSessionChange(checked) }
              onstyle="outline-success"
              offstyle="outline-secondary"
            />
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default Settings;