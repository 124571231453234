import './App.scss';
import React, { useEffect, useState } from 'react';
import Home from './components/home';
import { Navbar, Container, Nav, NavDropdown, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HeatMap from './components/heatmap';
import About from './components/about';
import ViewSelfProfile from './components/viewSelfProfile';
import Settings from './components/settings';
import Groups from './components/groups';
import awsmobile from './aws-exports';
import { Amplify, Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { sendLocation } from './components/initSocket/emit';
import { connectSocket } from './components/initSocket';
import { Globals } from './globals';
import logoText from "./components/images/OUTLND-text-logo.png";

Amplify.configure(awsmobile);
const locErrHandler = (error) => {
  console.error(error);
}
const locOptions = {
  enableHighAccuracy: true,
  maximumAge: 10000
}

function App({ signOut, user }) {
  const [currLoc, setCurrLoc] = useState({
    coords: {
      latitude: 43,
      longitude: -88
    }
  });
  const [activeUser, setActiveUser] = useState({});
  const [locations, setLocations] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(true);
  const [interests, setInterests] = useState([]);
  const [userStatusUpdateStatus, setUserStatusUpdateStatus] = useState("none");
  const [userStatus, setUserStatus] = useState({
    id: '',
    interest_id: '',
    user_id: '',
    defaultValue: '',
    username: '',
    details: ''
  });

  const signOutApp = () => {
    signOut();
  }

  const closeModal = () => {
    setShowStatusModal(false)
  }
  
  const updateUserStatusProperty = (event) => {
    setUserStatus((current) => {
      let eventUpdate = {};
      eventUpdate[event.target.name] = event.target.value;
      let merged = {...current, ...eventUpdate};
      return merged;
    });
  }

  const submitStatusForm = async (event) => {
    event.preventDefault();

    let session = await Auth.currentSession();
    let jwt = session.getAccessToken().getJwtToken();
    let activity = interests.find(obj => { return obj.id === parseInt(userStatus.interest_id) });

    console.log(`Updating user status for user with ID: ${user.id}`);
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({...userStatus, ...{username: user.username, value: activity.value}})
    }
    console.log(requestOptions);
    fetch(`${Globals.authApiUrl}/web/userStatus`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          closeModal();
        }
        else {
          setUserStatusUpdateStatus("failed");
        }
        return response.json();
      });
  }

  useEffect(() => {
    // Connect the socket
    let socket = connectSocket({ setLocations });

    // Ensure user exists in DB
    fetch(`${Globals.apiUrl}/${Globals.envName}/users?username=${user.username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length === 0) {
          console.log("No user found, creating user...")
          const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"username": user.username, "email": user.attributes.email})
          }
          fetch(`${Globals.apiUrl}/${Globals.envName}/users`, requestOptions)
            .then(response => {
              if (response.status === 200) {
                console.log("User successfully created!")
              }
              else {
                console.log("User creation failed!")
              }
              return response.json();
            })
            .then(data => {
              console.log(data);
            });
        }
        else {
          console.log("User found!")
        }
      });

    // Fetch user status
    fetch(`${Globals.apiUrl}/${Globals.envName}/userStatus?username=${user.username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setUserStatus(data[0]);
        }
      });

    // Fetch interests
    fetch(`${Globals.apiUrl}/${Globals.envName}/interests`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setInterests(data);
        }
      });

    // Set active user
    setActiveUser({
      username: user.username
    });

    // One time initial grab of location
    navigator.geolocation.getCurrentPosition((position) => {
      sendLocation(socket, user, position);
      setCurrLoc(position);
    }, locErrHandler, locOptions)

    // Check location every ten seconds
    // I'm aware of the existence of "watchPosition", but chrome mobile freezes when that's used
    if (navigator.geolocation) {
      setInterval(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          if (socket.readyState === socket.CLOSED) {
            console.log("Socket was closed, reconnecting...")
            socket = connectSocket({ setLocations });
          }
          sendLocation(socket, user, position);
          setCurrLoc(position);
        }, locErrHandler, locOptions)
      }, 5000);
    }
    else {
      console.error("Your browser does not support geolocation.")
    }
  }, [setCurrLoc, setActiveUser, setLocations, user]);

  return (
    <Container fluid>
      <Router>
        <Navbar bg="light" expand="lg">
            <Navbar.Brand>
              <Link className='menu-link' to="/">
                <img className="txtLogo" src={logoText} alt="text logo" style={{ width: "auto", height: "auto", maxHeight: "1.5em"}}></img>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="Menu" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/">Live User Map</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/heatmap">OutDoor Heatmap</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/about">About OutLnd</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="User" align="end" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/selfprofile">Your Profile</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/settings">Your Settings</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/groups">Groups</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <Button onClick={signOutApp} className='sign-out-btn'>Sign Out</Button>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
        <Modal show={showStatusModal} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>What are you up to?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={submitStatusForm}>
              <Form.Group className="mb-3">
                <Form.Label style={{ float: 'left'}}>Activity:</Form.Label>
                <Form.Select aria-label="statusSelect" value={userStatus.interest_id} name="interest_id" onChange={ (event) => updateUserStatusProperty(event) }>
                  <option>What are you doing?</option>
                  {
                    interests.map((interest) => {
                      return <option key={interest.id} value={interest.id}>{interest.value}</option>
                    })
                  }
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ float: 'left'}}>Details:</Form.Label>
                <Form.Control value={userStatus.details} name="details" onChange={ (event) => updateUserStatusProperty(event) }></Form.Control>
                <br></br>
              </Form.Group>
              <Row>
                <Col>
                  <Col style= {{ display: userStatusUpdateStatus === "failed" ? "block" : "none", float: 'left' }}>
                      <p style={{ color: "red" }}>Status update failed!</p>
                  </Col>
                  <Button variant="primary" type="submit" style={{ float: 'right'}}>
                    Update Status
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
        <Routes style={{float: "left"}}>
          <Route exact path="*" element={<Home currentLocation={currLoc} locations={locations} activeUser={ activeUser }></Home>}></Route>
          <Route exact path="/heatmap" element={ <HeatMap></HeatMap> }></Route>
          <Route exact path="/about" element={ <About></About> }></Route>
          <Route exact path="/selfprofile/*" element={ <ViewSelfProfile activeUser={ activeUser }></ViewSelfProfile> }></Route>
          <Route exact path="/settings" element={ <Settings activeUser={ activeUser }></Settings> }></Route>
          <Route exact path="/groups" element={ <Groups activeUser={ activeUser }></Groups> }></Route>
        </Routes>
      </Router>
    </Container>
  );
}

export default withAuthenticator(App);
