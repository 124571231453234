const globals = {
  apiUrl: "https://tuwe4y4ukc.execute-api.us-east-1.amazonaws.com",
  authApiUrl: "https://9espav36sd.execute-api.us-east-1.amazonaws.com",
  envName: "dev",
  socketUrl: "wss://9x2zedwcra.execute-api.us-east-1.amazonaws.com",
  appName: "OutLand",
  googleMapsApiKey: "AIzaSyADRFxsnSDaydaxyQcztatSFKHHV5aDwdY",
  defaultMapZoom: 12
};

export const Globals = globals;