
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { GiPositionMarker, GiBoatFishing, GiGrassMushroom, GiMountainClimbing } from 'react-icons/gi';
import { BiTargetLock } from 'react-icons/bi';
import { MdDirectionsBike } from 'react-icons/md';
import { TbKayak } from 'react-icons/tb';
import { FaHiking } from 'react-icons/fa';

export default class Marker extends PureComponent {
  static defaultProps = {};

  renderIcon = (activity) => {
    const styles = {
      color: this.props.username === this.props.activeUser.username ? "lime" : "orange",
      fontSize: '50px',
      filter: "drop-shadow(-3px 5px 2px rgb(0 0 0 / 0.5))"
    }

    switch(activity) {
      case "Kayaking":
        return <TbKayak style={styles} />;
      case "Hiking":
        return <FaHiking style={styles} />;
      case "Biking":
        return <MdDirectionsBike style={styles} />;
      case "Hunting":
        return <BiTargetLock style={styles} />;
      case "Fishing":
        return <GiBoatFishing style={styles} />;
      case "Foraging":
        return <GiGrassMushroom style={styles} />;
      case "Climbing":
        return <GiMountainClimbing style={styles} />;
      default:
        return <GiPositionMarker style={styles} />;
    }
  }

  render() {
    return (
      <Link to={`/userProfile?username=${this.props.username}`}>
        {this.renderIcon(this.props.activity)}
      </Link>
    );
  }
}
