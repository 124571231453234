import bannerImage from "./images/OUTLND-Banner.png";
import profileImage from "./images/OUTLND-Profile.png";
import { Row, Col, Container, Badge } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Globals } from "../globals";
import { useSearchParams } from "react-router-dom";

function UserProfile(props) {
  const [searchParams] = useSearchParams();
  const [userProfile, setUserProfile] = useState({
    name: '',
    locale: '',
    about: '',
    status: ''
  });
  const [userStatus, setUserStatus] = useState({
    id: '',
    interest_id: '',
    user_id: '',
    value: '',
    username: '',
    details: ''
  })
  const [userInterests, setUserInterests] = useState([])
  const username = searchParams.get("username");

  useEffect(() => {
    // Fetch user profile
    fetch(`${Globals.apiUrl}/${Globals.envName}/socialProfiles?username=${username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setUserProfile(data[0]);
        }
      });

    // Fetch user status
    fetch(`${Globals.apiUrl}/${Globals.envName}/userStatus?username=${username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setUserStatus(data[0]);
        }
      });

    // Fetch user interests
    fetch(`${Globals.apiUrl}/${Globals.envName}/userInterests?username=${username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setUserInterests(data);
        }
      });
  }, [setUserProfile, setUserInterests, setUserStatus, username]);

  return (
    <Container fluid className="profileContainer">
      <Row>
        <Col>
          <div className="profileHeader">
            <img className="bannerImage" src={bannerImage} alt="User banner"></img>
            <img className="profileImage" src={profileImage} alt="User profile"></img>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="profileData">
            <p><b>{userProfile.given_name} {userProfile.family_name}</b></p>
            <p>{userProfile.locale}</p>
            <p>{userProfile.about}</p>
          </div>
        </Col>
      </Row>

      <Row style={{ paddingLeft: '2%' }}>
        <Col>
          <Row>
            <Col>
              <b style={{float: 'left'}}>Interests</b>
            </Col>
          </Row>
          <Row>
            <Col>
              {userInterests.map((interest) => {
                return (<Badge pill style={{marginRight: '.5em', marginBottom: '.5em', float: 'left'}} bg="secondary" key={interest.value}>{interest.value}</Badge>);
              })}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ float: 'left', paddingLeft: '2%', marginTop: '1em' }}>
        <Col>
          <b style={{ float: 'left'}}>Current Status: </b><br />
          <p style={{ float: 'left'}}>{userStatus.value}</p>
        </Col>
      </Row>
    </Container>
  )
}

export default UserProfile;