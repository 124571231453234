import { Globals } from '../../globals';

export const sendLocation = async (socket, user, position) => {
  let msg = {
    userId: user.username,
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
    appName: Globals.appName,
    action: "updateLocation",
    unixDate: Date.now()
  }

  while (socket.readyState === socket.CONNECTING) {
    await new Promise(r => setTimeout(r, 2000));
  };

  if (socket.readyState === socket.OPEN) {
    socket.send(JSON.stringify(msg));
  }
};