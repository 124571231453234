import bannerImage from "./images/OUTLND-Banner.png";
import profileImage from "./images/OUTLND-Profile.png";
import { Row, Col, Container, Badge, Button, Modal, Form, CloseButton } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Globals } from "../globals";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AiOutlineEdit } from "react-icons/ai";

function ViewSelfProfile(props) {
  const [userProfile, setUserProfile] = useState({
    name: '',
    locale: '',
    about: '',
    status: ''
  });
  const [userStatus, setUserStatus] = useState({
    id: '',
    interest_id: '',
    user_id: '',
    value: '',
    username: '',
    details: ''
  });
  const [user, setUser] = useState({
    id: '',
    email: ''
  });
  const [userInterests, setUserInterests] = useState([]);
  const [interests, setInterests] = useState([]);
  const [profileUpdateStatus, setProfileUpdateStatus] = useState("none");
  const [userStatusUpdateStatus, setUserStatusUpdateStatus] = useState("none");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showInterestsModal, setShowInterestsModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  useEffect(() => {
    // Fetch user and set user ID values
    fetch(`${Globals.apiUrl}/${Globals.envName}/users?username=${props.activeUser.username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          console.log(data)
          setUser(data[0]);
          setUserProfile((current) => {
            let userIdUpdate = {'user_id': data[0].id};
            let merged = {...current, ...userIdUpdate};
            console.log(merged);
            return merged;
          });
          setUserStatus((current) => {
            let userIdUpdate = {'user_id': data[0].id};
            let merged = {...current, ...userIdUpdate};
            console.log(merged);
            return merged;
          })
        }
      });

    // Fetch user profile
    fetch(`${Globals.apiUrl}/${Globals.envName}/socialProfiles?username=${props.activeUser.username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          console.log(data[0])
          setUserProfile(data[0]);
        }
      });

    // Fetch user status
    fetch(`${Globals.apiUrl}/${Globals.envName}/userStatus?username=${props.activeUser.username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          console.log(data[0])
          setUserStatus(data[0]);
        }
      });

    // Fetch user interests
    fetch(`${Globals.apiUrl}/${Globals.envName}/userInterests?username=${props.activeUser.username}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          console.log(data[0])
          setUserInterests(data);
        }
      });

    // Fetch interests
    fetch(`${Globals.apiUrl}/${Globals.envName}/interests`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setInterests(data);
        }
      });
  }, [setUserProfile, setUserInterests, setUserStatus, props.activeUser]);

  const updateUserProfileProperty = (event) => {
    setUserProfile((current) => {
      let eventUpdate = {};
      eventUpdate[event.target.name] = event.target.value;
      let merged = {...current, ...eventUpdate};
      return merged;
    });
  }

  const updateUserStatusProperty = (event) => {
    setUserStatus((current) => {
      let eventUpdate = {};
      eventUpdate[event.target.name] = event.target.value;
      let merged = {...current, ...eventUpdate};
      return merged;
    });
  }

  const submitStatusForm = async (event) => {
    event.preventDefault();

    let session = await Auth.currentSession();
    let jwt = session.getAccessToken().getJwtToken();
    let activity = interests.find(obj => { return obj.id === parseInt(userStatus.interest_id) });

    console.log(`Updating user status for user with ID: ${user.id}`);
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({...userStatus, ...{username: props.activeUser.username, value: activity.value}})
    }
    console.log(requestOptions);
    fetch(`${Globals.authApiUrl}/web/userStatus`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          setUserStatusUpdateStatus("success");
          setShowStatusModal(false);
        }
        else {
          setUserStatusUpdateStatus("failed");
        }
        return response.json();
      });
  }

  const submitProfileForm = async (event) => {
    let session = await Auth.currentSession();
    let jwt = session.getAccessToken().getJwtToken();
    console.log(`Updating user profile for user with ID: ${user.id}`);
    event.preventDefault();
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({...userProfile, ...{username: props.activeUser.username}})
    }
    fetch(`${Globals.authApiUrl}/web/socialProfiles`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          setProfileUpdateStatus("success");
          setShowDetailsModal(false);
        }
        else {
          setProfileUpdateStatus("failed");
        }
        return response.json();
      })
  }

  const addUserInterest = async (event) => {
    if (event.target.value !== 'doNothing') {
      let session = await Auth.currentSession();
      let jwt = session.getAccessToken().getJwtToken();
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`
        },
        body: JSON.stringify({username: props.activeUser.username, interestId: event.target.value})
      }
      console.log(requestOptions);
      fetch(`${Globals.authApiUrl}/web/userInterests`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.length > 0) {
            setUserInterests(data);
          }
          else {
            setUserInterests([]);
          }
        });
    }
  }

  const deleteUserInterest = async (id) => {
    let session = await Auth.currentSession();
    let jwt = session.getAccessToken().getJwtToken();
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({username: props.activeUser.username, interestId: id})
    }
    console.log(requestOptions);
    fetch(`${Globals.authApiUrl}/web/userInterests`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.length > 0) {
          setUserInterests(data);
        }
        else {
          setUserInterests([]);
        }
      });
  }

  return (
    <Container fluid className="profileContainer">
      <Row>
        <Col>
          <div className="profileHeader">
            <img className="bannerImage" src={bannerImage} alt="User banner"></img>
            <img className="profileImage" src={profileImage} alt="User profile"></img>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="profileData">
            <p style={{ float: 'left' }}><b>{userProfile.given_name} {userProfile.family_name}</b></p>
            <Button variant="light" onClick={() => setShowDetailsModal(true)} style={{ float: 'right' }}><AiOutlineEdit style={{ fontSize: "25px" }}></AiOutlineEdit></Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{userProfile.locale}</p>
        </Col>
      </Row>
      <Row>
        <Col>
            <p>{userProfile.about}</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col>
              <b style={{float: 'left'}}>Interests</b>
            </Col>
            <Col>
              <Button variant="light" onClick={() => setShowInterestsModal(true)} style={{ float: 'right' }}><AiOutlineEdit style={{ fontSize: "25px" }}></AiOutlineEdit></Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {userInterests.map((interest) => {
                return (<Badge pill style={{marginRight: '.5em', marginBottom: '.5em', float: 'left'}} bg="secondary" key={interest.value}>{interest.value}</Badge>);
              })}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: '1em' }}>
        <Col>
          <b style={{ float: 'left'}}>Current Status: </b><br />
          <p style={{ float: 'left'}}>{userStatus.value}</p>
        </Col>
        <Col>
          <Button variant="light" onClick={() => setShowStatusModal(true)} style={{ float: 'right' }}><AiOutlineEdit style={{ fontSize: "25px" }}></AiOutlineEdit></Button>
        </Col>
      </Row>

      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)} centered>
        <Modal.Header closeButton>
          Personal Details
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitProfileForm}>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: 'left'}}>First Name:</Form.Label>
              <Form.Control value={userProfile.given_name} name="given_name" onChange={ (event) => updateUserProfileProperty(event) }></Form.Control>
              <br></br>
              <Form.Label style={{ float: 'left'}}>Last Name:</Form.Label>
              <Form.Control value={userProfile.family_name} name="family_name" onChange={ (event) => updateUserProfileProperty(event) }></Form.Control>
              <br></br>
              <Form.Label style={{ float: 'left'}}>Locale:</Form.Label>
              <Form.Control value={userProfile.locale} name="locale" onChange={ (event) => updateUserProfileProperty(event) }></Form.Control>
              <br></br>
              <Form.Label style={{ float: 'left'}}>About:</Form.Label>
              <Form.Control as="textarea" value={userProfile.about} name="about" onChange={ (event) => updateUserProfileProperty(event) }></Form.Control>
              <br></br>
            </Form.Group>
            <Row>
              <Col>
                <Button variant="primary" type="submit" style={{ float: 'left'}}>
                  Update Profile
                </Button>
              </Col>
              <Col style= {{ display: profileUpdateStatus === "success" ? "block" : "none" }}>
                  <p style={{ color: "green" }}>Profile updated!</p>
              </Col>
              <Col style= {{ display: profileUpdateStatus === "failed" ? "block" : "none" }}>
                  <p style={{ color: "red" }}>Profile update failed!</p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)} centered>
        <Modal.Header closeButton>
          Update Status
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitStatusForm}>
            <Form.Group className="mb-3">
              <Form.Label style={{ float: 'left'}}>Activity:</Form.Label>
              <Form.Select aria-label="statusSelect" value={userStatus.interest_id} name="interest_id" onChange={ (event) => updateUserStatusProperty(event) }>
                <option>What are you doing?</option>
                {
                  interests.map((interest) => {
                    return <option key={interest.id} value={interest.id}>{interest.value}</option>
                  })
                }
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label style={{ float: 'left'}}>Details:</Form.Label>
              <Form.Control value={userStatus.details} name="details" onChange={ (event) => updateUserStatusProperty(event) }></Form.Control>
              <br></br>
            </Form.Group>
            <Row>
              <Col>
                <Button variant="primary" type="submit" style={{ float: 'left'}}>
                  Update Status
                </Button>
              </Col>
              <Col style= {{ display: userStatusUpdateStatus === "success" ? "block" : "none" }}>
                  <p style={{ color: "green" }}>Status updated!</p>
              </Col>
              <Col style= {{ display: userStatusUpdateStatus === "failed" ? "block" : "none" }}>
                  <p style={{ color: "red" }}>Status update failed!</p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showInterestsModal} onHide={() => setShowInterestsModal(false)} centered>
        <Modal.Header closeButton>
          Interests
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Row>
              <Col>
                <b style={{float: 'left'}}>Interests</b>
              </Col>
            </Row>
            <Row>
              <Form onSubmit={submitStatusForm}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ float: 'left'}}>Select an Interest to Add:</Form.Label>
                  <Form.Select aria-label="statusSelect" name="interest_id" onChange={ (event) => addUserInterest(event) }>
                    <option value='doNothing'>What do you like to do?</option>
                    {
                      interests.map((interest) => {
                        return <option key={interest.id} value={interest.id}>{interest.value}</option>
                      })
                    }
                  </Form.Select>
                </Form.Group>
              </Form>
            </Row>
            <Row>
              <Col>
                <b style={{float: 'left'}}>Current Interests:</b>
              </Col>
            </Row>
            <Row>
              <Col>
                {userInterests.map((interest) => {
                  return (<Badge pill style={{marginRight: '.5em', marginBottom: '.5em', float: 'left'}} bg="secondary" key={interest.value}>{interest.value} <CloseButton onClick={() => deleteUserInterest(interest.interest_id)} /></Badge>);
                })}
              </Col>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default ViewSelfProfile;