import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";

const getMapOptions = (maps) => {
  return {
      // streetViewControl: false,
      // scaleControl: true,
      // fullscreenControl: false,
      // styles: [{
      //     featureType: "poi.business",
      //     elementType: "labels",
      //     stylers: [{
      //         visibility: "off"
      //     }]
      // }],
      // gestureHandling: "greedy",
      // disableDoubleClickZoom: true,
      // minZoom: 11,
      // maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId["SATELLITE"],
      mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
              maps.MapTypeId.ROADMAP,
              maps.MapTypeId.SATELLITE,
              maps.MapTypeId.HYBRID,
              maps.MapTypeId.TERRAIN
          ]
      },

      // zoomControl: true,
      // clickableIcons: false
  };
}

const HeatMap = () => {
  const [heatmap, setHeatmap] = useState(
    {
      positions: [],
      options: {
        radius: 20,
        opacity: 0.8
      }
    }
  );
  const defaultProps = {
    apiKey: "AIzaSyADRFxsnSDaydaxyQcztatSFKHHV5aDwdY",
    center: {
      lat: 43.01425143,
      lng: -88.47269873
    },
    zoom: 4
  };

  useEffect(() => {
    fetch('https://l0b0iqdrh0.execute-api.us-east-1.amazonaws.com/dev/archivedLocations', {
      method: 'GET',
      headers: {
        'x-api-key': 'QHlLrgbyX06iHAMklCbHA6fDmIOiDpki8S8uZblk'
      }
    })
    .then(response => response.json())
    .then(result => {
      console.log('Success');
      let newLocs = result.locations.map(pos => {
        let newPos = {
          lat: pos.lat,
          lng: pos.lon,
          weight: 1
        };
        return newPos;
      });
      console.log(newLocs);
      setHeatmap(
        {
          positions: newLocs,
          options: {
            radius: 20,
            opacity: 0.8
          }
        }
      );
    })
    .catch(error => {
      console.error('Error:', error);
    });

  }, [setHeatmap])

  return(
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: defaultProps.apiKey
        }}
        heatmapLibrary={true}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        heatmap={heatmap}
        options={getMapOptions}
      >
      </GoogleMapReact>
    </div>
  )
}

export default HeatMap;