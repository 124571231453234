import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./marker";
import { Globals } from "../globals";

const getMapOptions = (maps) => {
  return {
      // streetViewControl: false,
      // scaleControl: true,
      // fullscreenControl: false,
      // styles: [{
      //     featureType: "poi.business",
      //     elementType: "labels",
      //     stylers: [{
      //         visibility: "off"
      //     }]
      // }],
      // gestureHandling: "greedy",
      // disableDoubleClickZoom: true,
      // minZoom: 11,
      // maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId["SATELLITE"],
      mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
              maps.MapTypeId.ROADMAP,
              maps.MapTypeId.SATELLITE,
              maps.MapTypeId.HYBRID,
              maps.MapTypeId.TERRAIN
          ]
      },

      // zoomControl: true,
      // clickableIcons: false
  };
}

const UserMap = (props) => {
  const [center, setCenter] = useState({lat: props.currentLocation.coords.latitude, lng: props.currentLocation.coords.longitude});
  
  useEffect(() => {
    setCenter({lat: props.currentLocation.coords.latitude, lng: props.currentLocation.coords.longitude})
  }, [setCenter, props.locations, props.currentLocation]);

  return(
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: Globals.googleMapsApiKey
        }}
        center={center}
        defaultZoom={Globals.defaultMapZoom}
        options={getMapOptions}
      >
        {
          props.locations.map((loc, index) => {
            return <Marker key={index} lat={loc.lat} lng={loc.lng} username={loc.userId} activity={loc.activity} activeUser={props.activeUser}></Marker>
          })
        }
      </GoogleMapReact>
    </div>
  )
}

export default UserMap;