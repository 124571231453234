import { Row, Col, Container, Form, Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Globals } from "../globals";
import { Auth } from 'aws-amplify';
import { TiDeleteOutline } from 'react-icons/ti';
import { AiOutlineEye } from 'react-icons/ai';
import { BsPersonPlus } from 'react-icons/bs'
import { BiExit } from 'react-icons/bi'
import { FaCheck, FaTimes } from 'react-icons/fa';

function Groups(props) {
  const [group, setGroup] = useState(null);
  const [groupInvitations, setGroupInvitations] = useState([]);
  const [displayGroupCreateModal, setDisplayGroupCreateModal] = useState(false);
  const [displayViewMembersModal, setDisplayViewMembersModal] = useState(false);
  const [displayAddMembersModal, setDisplayAddMembersModal] = useState(false);
  const [displayDisbandGroupModal, setDisplayDisbandGroupModal] = useState(false);
  const [displayLeaveGroupModal, setDisplayLeaveGroupModal] = useState(false);
  const [createGroupName, setCreateGroupName] = useState('');
  const [groupCreateStatus, setGroupCreateStatus] = useState(null);
  const [searchUser, setSearchUser] = useState('');
  const [foundUser, setFoundUser] = useState(null);
  const [inviteUserResultText, setInviteUserResultText] = useState(null);

  const invitationSentSuccessString = "Invitation Sent!";
  const groupCreationSuccessString = "Group successfully created!";

  const getGroupMemberships = (jwt) => {
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    }
    fetch(`${Globals.authApiUrl}/web/groupMemberships?username=${props.activeUser.username}`, options)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          console.log(data[0]);
          setGroup(data[0]);
        }
      });
  }

  const getGroupInvitations = (jwt) => {
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    }

    fetch(`${Globals.authApiUrl}/web/groupInvitations?username=${props.activeUser.username}`, options)
    .then(response => response.json())
    .then(data => {
      setGroupInvitations(data);
    });
  }

  const rejectGroupInvite = async (invitationId) => {
    const session = await Auth.currentSession();
    const jwt = session.getAccessToken().getJwtToken();

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        username: props.activeUser.username,
        invitation_id: invitationId
      })
    }
    fetch(`${Globals.authApiUrl}/web/groupInvitations`, options)
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          getGroupInvitations(jwt);
          getGroupMemberships(jwt);
        }
      })
  }

  const acceptGroupInvite = async (invitationId) => {
    const session = await Auth.currentSession();
    const jwt = session.getAccessToken().getJwtToken();

    const options = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        username: props.activeUser.username,
        invitation_id: invitationId
      })
    }
    fetch(`${Globals.authApiUrl}/web/groupMemberships`, options)
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          getGroupInvitations(jwt);
          getGroupMemberships(jwt);
        }
      })
  }

  useEffect(() => {
    // Fetch user's groups
    Auth.currentSession()
      .then((res) => {
        let jwt = res.getAccessToken().getJwtToken();
        getGroupMemberships(jwt);
        getGroupInvitations(jwt);
      });
  }, [setGroup, setGroupInvitations, props.activeUser]);

  const renderGroupMembership = () => {
    if (group) {
      return (
        <Row style={{ paddingTop: '1em' }}>
          <Col>
            {group.name}
          </Col>
          <Col>
            <Button variant="outline-danger" style={{ display: group.is_owner ?  "" : "none", float: "right", marginLeft: "5px" }} onClick={() => setDisplayDisbandGroupModal(true)}><TiDeleteOutline></TiDeleteOutline></Button>
            <Button variant="outline-danger" style={{ display: !group.is_owner ?  "" : "none", float: 'right', marginLeft: "5px" }} onClick={() => setDisplayLeaveGroupModal(true)}><BiExit></BiExit></Button>
            <Button variant="outline-success" style={{ display: group.is_owner ?  "" : "none", float: 'right', marginLeft: "5px" }} onClick={() => setDisplayAddMembersModal(true)}><BsPersonPlus></BsPersonPlus></Button>
            <Button variant="outline-info" style={{ float: 'right', marginLeft: "5px" }} onClick={() => setDisplayViewMembersModal(true)}><AiOutlineEye></AiOutlineEye></Button>
          </Col>
        </Row>
      )
    }
    else {
      return (
        <Row style={{ paddingTop: '1em' }}>
          <Col>
            You are not currently in a group.
          </Col>
        </Row>
      )
    }
  };

  const renderGroupInvitations = () => {
    if (groupInvitations.length > 0) {
      return (
        <div>
          {
            groupInvitations.map((invite, index) => {
              return (
                <Row style={{ paddingTop: '1em' }} key={index}>
                  <Col>
                    <p>Invited to <b>{invite.group_name}</b> by <b>{invite.host_username}</b></p>
                  </Col>
                  <Col>
                    <Button variant="outline-danger" style={{ float: 'right', marginLeft: "5px" }} onClick={() => rejectGroupInvite(invite.invitation_id)}><FaTimes></FaTimes></Button>
                    <Button variant="outline-success" style={{ float: 'right', marginLeft: "5px" }} onClick={() => acceptGroupInvite(invite.invitation_id)}><FaCheck></FaCheck></Button>
                  </Col>
                </Row>
              )
            })
          }
        </div>
      )
    }
    else {
      return (
        <Row style={{ paddingTop: '1em' }}>
          <Col>
            You have no current group invitations.
          </Col>
        </Row>
      )
    }
  };

  const submitGroupCreateForm = async (event) => {
    event.preventDefault();
    let session = await Auth.currentSession();
    let jwt = session.getAccessToken().getJwtToken();

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        name: createGroupName,
        username: props.activeUser.username
      })
    }
    
    fetch(`${Globals.authApiUrl}/web/groups`, options)
      .then(response => {
        if (response.status === 200) {
          setDisplayGroupCreateModal(false);
          getGroupMemberships(jwt);
        };
        return response.json();
      })
      .then(data => {
        console.log(data);
        if (data.command === "INSERT") {
          setGroupCreateStatus(groupCreationSuccessString);
          return;
        }

        switch (data.code) {
          // Group ID was null, this happens when a group is not created because a user is already part of one
          case ("23502"):
            setGroupCreateStatus("You are already part of a group!")
            break;
          // Group name is taken
          case ("23505"):
            setGroupCreateStatus("This group name is already taken!")
            break;
          default:
            break;
        }
      });
  };

  const disbandGroup = async () => {
    let session = await Auth.currentSession();
    let jwt = session.getAccessToken().getJwtToken();

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        group_id: group.group_id,
        username: props.activeUser.username
      })
    }
    fetch(`${Globals.authApiUrl}/web/groups`, options)
      .then(response => {
        if (response.status === 200) {
          setGroup(null);
          setDisplayDisbandGroupModal(false);
        };
      });
  };

  const leaveGroup = async () => {
    let session = await Auth.currentSession();
    let jwt = session.getAccessToken().getJwtToken();

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        group_id: group.group_id,
        username: props.activeUser.username
      })
    }
    fetch(`${Globals.authApiUrl}/web/groupMemberships`, options)
      .then(response => {
        if (response.status === 200) {
          setGroup(null);
          getGroupInvitations(jwt);
          getGroupMemberships(jwt);
          setDisplayLeaveGroupModal(false);
        };
      });
  }

  const searchForUser = (event) => {
    event.preventDefault();
    // Fetch user and set user ID values
    fetch(`${Globals.apiUrl}/${Globals.envName}/users?username=${searchUser}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          console.log(data[0]);
          setFoundUser(data[0]);
        }
      });
  }

  const inviteUserToGroup = async () => {
    setInviteUserResultText(null);

    let session = await Auth.currentSession();
    let jwt = session.getAccessToken().getJwtToken();

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        group_id: group.group_id,
        username: props.activeUser.username,
        invitee: foundUser.id
      })
    }
    fetch(`${Globals.authApiUrl}/web/groupInvitations`, options)
      .then(response => response.json())
      .then(data => {
        if (data?.code === "23505") {
          setInviteUserResultText("This user was already invited to this group!")
        }
        else if (data?.command === "INSERT") {
          data.rowCount > 0 ? setInviteUserResultText("Invitation Sent!") : setInviteUserResultText("This user is already a member of this group!")
        }
      });
  }

  return (
    <Container fluid className="groupsContainer">
      <Row style={{ paddingTop: '1em' }}>
        <Col>
          <b style={{ float: 'left' }}>Current Group Membership:</b>
        </Col>
      </Row>

      { renderGroupMembership() }

      <Row style={{ paddingTop: '1em' }}>
        <Col>
          <b style={{ float: 'left' }}>Group Invitations:</b>
        </Col>
      </Row>

      { renderGroupInvitations() }

      <Row style={{ paddingTop: '1.5em', display: group ? "none" : "" }}>
        <Col>
          <Button onClick={() => setDisplayGroupCreateModal(true)} style={{ float: "right" }}>Create Group</Button>
        </Col>
      </Row>

      <Modal show={displayViewMembersModal} onHide={() => setDisplayViewMembersModal(false)} centered>
        <Modal.Header closeButton>
          View Members
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
      </Modal>

      <Modal show={displayDisbandGroupModal} onHide={() => setDisplayDisbandGroupModal(false)} centered>
        <Modal.Header closeButton>
          Disband Group?
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              Are you sure you want to disband this group?
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="danger" style={{ float: "left", marginLeft: "5px" }} onClick={() => setDisplayDisbandGroupModal(false)}><FaTimes></FaTimes></Button>
              <Button variant="success" style={{ float: "left", marginLeft: "5px" }} onClick={() => disbandGroup()}><FaCheck></FaCheck></Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={displayAddMembersModal} onHide={() => setDisplayAddMembersModal(false)} centered>
        <Modal.Header closeButton>
          Invite Members
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={searchForUser}>
            <Form.Group>
              <Form.Label style={{ float: 'left'}}>
                Find User by Username
              </Form.Label>
              <Form.Control value={searchUser} name="createGroupName" onChange={ (event) => setSearchUser(event.target.value) }>
                
              </Form.Control>
            </Form.Group>
            <br></br>
            <Row>
              <Col>
                <Button variant="primary" type="submit" style={{ float: 'right'}}>
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
          <br></br>
          <Row style={{ display: foundUser ? '' : 'none'}}>
            <Col>
              {foundUser?.username}
            </Col>
            <Col>
              <Button variant="outline-success" style={{ float: 'right', marginLeft: "5px" }} onClick={ () => inviteUserToGroup() }><BsPersonPlus></BsPersonPlus></Button>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col style= {{ display: inviteUserResultText ? "block" : "none" }}>
                <p style={{ color: inviteUserResultText === invitationSentSuccessString ? "green" : "red" }}>{inviteUserResultText}</p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={displayLeaveGroupModal} onHide={() => setDisplayLeaveGroupModal(false)} centered>
        <Modal.Body>
          Leave Group?
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="danger" style={{ float: "left", marginLeft: "5px" }} onClick={() => setDisplayLeaveGroupModal(false)}><FaTimes></FaTimes></Button>
              <Button variant="success" style={{ float: "left", marginLeft: "5px" }} onClick={() => leaveGroup()}><FaCheck></FaCheck></Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      
      <Modal show={displayGroupCreateModal} onHide={() => setDisplayGroupCreateModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitGroupCreateForm}>
            <Form.Group>
              <Form.Label style={{ float: 'left'}}>Group Name:</Form.Label>
              <Form.Control value={createGroupName} name="createGroupName" onChange={ (event) => setCreateGroupName(event.target.value) }></Form.Control>
              <br></br>
            </Form.Group>
            <Row>
              <Col>
                <Col style= {{ display: groupCreateStatus ? "block" : "none", float: 'left' }}>
                    <p style={{ color: groupCreateStatus === groupCreationSuccessString ? "green" : "red" }}>{groupCreateStatus}</p>
                </Col>
                <Button variant="primary" type="submit" style={{ float: 'right'}}>
                  Create Group
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default Groups;