import React from "react";
import { Route, Routes } from "react-router-dom";
import UserProfile from "./userProfile";
import UserMap from "./usermap";

const Home = (props) => {
  return(
    <div>
        <Routes>
            <Route exact path="/" element={ <UserMap  currentLocation={props.currentLocation} locations={props.locations} activeUser={props.activeUser}></UserMap> }></Route>
            <Route exact path="/userProfile/*" element={ <UserProfile></UserProfile> }></Route>
        </Routes>
    </div>
  )
}

export default Home;