import { Globals } from "../../globals";

export const connectSocket = ({ setLocations }) => {
  const socket = new WebSocket(`${Globals.socketUrl}/${Globals.envName}`);

  socket.onmessage = (message) => {
    const data = JSON.parse(message.data);
    
    if (Array.isArray(data)) {
      setLocations(data);
    }
    else {
      console.log(`Invalid data: ${JSON.stringify(data)}`)
    }
  };

  socket.onopen = () => {
    console.log("Socket connected.")
  };

  socket.onclose = () => {
    console.log("Socket connection closed.")
  };

  return socket;
}